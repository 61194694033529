import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const Item = ({ className, name, price }) => {
  return (
    <div className={`grid-item rental-${className}`}>
      <h2>{name}</h2>
      <p>Winnipeg</p>

      <span className="grid-item__link">RENTED for ${price}</span>
    </div>
  );
};

const IndexPage = () => (
  <Layout page="home">
    <div className="container black">
      <div className="content">
        <div className="split">
          <h2>About Yellow Dog</h2>

          <p>
            We are experienced real estate entrepreneurs with a proven track
            record of acquiring profitable real estate deals with predictable
            cash-flow and appreciation. We work with joint venture
            partners/investors who want to become involved in real estate but
            have limited time and/or expertise.
            <br />
            <strong>Our investors always get paid first!</strong>
          </p>
        </div>

        <div className="split">
          <h2>Our Process</h2>

          <p>
            Use multiple marketing strategies and proven negotiation systems to
            acquire profitable real estate deal. Focus on generating cashflow,
            building equity and long term wealth. Manage risk with systems and
            sound fundamentals. Outperform traditional investment markets.
          </p>
        </div>
      </div>
    </div>

    <div className="container grid">
      <Item name="203 Pandora Ave" price="1450" className="203-pandora" />
      <Item name="342 Harvard Ave" price="1350" className="342-harvard" />
      <Item name="310 Victoria Ave" price="1295" className="310-victoria" />
      <Item name="733 Fleet" price="2510" className="733-fleet" />
      <Item name="229 Bertrand" price="2850" className="229-bertrand" />

      <div className="grid-item-last">
        <h3>Home Rentals</h3>

        <p>We have homes for rent.</p>

        <Link to="/page-2/">Apply</Link>

        <hr />

        <h3>Rent-to-Own</h3>

        <p>Need to get into home ownership but have:</p>

        <ul>
          <li>Bad credit?</li>
          <li>Not enough down payment?</li>
          <li>Bad debt?</li>
        </ul>

        <p>
          Our Rent-to-Own option might
          <br />
          just be right for you.
        </p>

        <Link to="/">Apply</Link>
      </div>
    </div>

    <div className="container quote">
      <div className="content">
        <h3>
          We believe great deals are not found, but they are created. We believe
          in providing win-win solutions for our joint venture partners as well
          as our tenants.
        </h3>
      </div>
    </div>

    <div className="container about">
      <div className="split corgi" />

      <div className="split text">
        <h2>Since 2016</h2>

        <p>
          Casey has been investing in Real Estate since 2016, has spent hundreds
          of hours on education and studying the market.
        </p>

        <h2>Creating Value</h2>

        <p>We create value through 3 different strategies.</p>

        <ul>
          <li>Buy-Fix-Hold</li>
          <li>Rent-to-Own</li>
          <li>Buy-Fix-Sell</li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
